import { BookingStatus_Enum, JobStatus_Enum } from './hasura/__generated__';

export const isBrowser =
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  typeof navigator !== 'undefined';

const getEnv = () => (isBrowser ? window.ENV : process.env);

const env = getEnv();

export const NODE_ENV = env.NODE_ENV || 'development';
export const SENTRY_DSN =
  'https://a90a78ffedde738ba2067e593d8b5144@o4507294502158336.ingest.us.sentry.io/4507389556293632';
export const HASURA_GRAPHQL_URI =
  env.HASURA_GRAPHQL_URI || 'http://localhost:8080/v1/graphql';
export const HASURA_GRAPHQL_WS_URI =
  env.HASURA_GRAPHQL_WS_URI || 'ws://localhost:8080/v1/graphql';
const S3_BUCKET_REGION = env.S3_BUCKET_REGION;

const S3_BUCKET_NAME = env.S3_BUCKET_NAME || 'test-bucket';
const S3_PRIVATE_BUCKET_NAME =
  env.S3_PRIVATE_BUCKET_NAME || 'test-private-bucket';

export const S3_BUCKET_URL =
  env.S3_BUCKET_URL ||
  (S3_BUCKET_REGION
    ? `https://s3.${S3_BUCKET_REGION}.amazonaws.com/${S3_BUCKET_NAME}`
    : `http://localhost:9000/${S3_BUCKET_NAME}`);

export const S3_BUCKET_CDN_URL = env.S3_BUCKET_CDN_URL
  ? env.S3_BUCKET_CDN_URL
  : S3_BUCKET_URL;

export const S3_PRIVATE_BUCKET_URL =
  env.S3_PRIVATE_BUCKET_URL ||
  (S3_BUCKET_REGION
    ? `https://s3.${S3_BUCKET_REGION}.amazonaws.com/${S3_PRIVATE_BUCKET_NAME}`
    : `http://localhost:9000/${S3_PRIVATE_BUCKET_NAME}`);

export const TAXES_VALUE = Number(env.DEFAULT_TAX_VALUE) || 0.14975;

export const ADDRESS_AUTOCOMPLETE_PROVIDER: 'google' | 'esri' =
  (env.ADDRESS_AUTOCOMPLETE_PROVIDER || 'esri') as 'google' | 'esri';
export const GOOGLE_PLACES_API_KEY = env.GOOGLE_PLACES_API_KEY || '';

export const STRIPE_PUBLIC_KEY =
  env.STRIPE_PUBLIC_KEY ||
  'pk_test_51ORux3HGw4AMLpfTcNxpvAs00cS1TgrOMC7YqeoXd8EoyMBkgRCRLSsluOJrF4GU9CeGGmbfNXNUYwjzKGMbZk9w00U1MoWqnF';

export const STRIPE_CONNECT_ONBOARDING_ENABLED =
  env.STRIPE_CONNECT_ONBOARDING_ENABLED
    ? Boolean(env.STRIPE_CONNECT_ONBOARDING_ENABLED)
    : true;

export const WEB_URL = env.WEB_URL || 'http://localhost:3000';

export const GOOGLE_RECAPTCHA_SITE_KEY =
  env.GOOGLE_RECAPTCHA_SITE_KEY || '6LfpBvspAAAAAEhMWkrrA6yIXMcK-SEnqZh0NTQn';

export const SEARCH_DEBOUNCE_VALUE = 350;

export const GTAG_ID = env.GTAG_ID || 'G-44J079ENJS';
export const HOTJAR_ID = env.HOTJAR_ID || '';

export const bookingStatusApptJobStatusMap: Record<
  BookingStatus_Enum,
  JobStatus_Enum
> = {
  [BookingStatus_Enum.AutomaticApproval]: JobStatus_Enum.Scheduled,
  [BookingStatus_Enum.WaitForApproval]: JobStatus_Enum.Pending,
};

export const GOOGLE_CAL_REDIRECT_URI =
  env.GOOGLE_CAL_REDIRECT_URI || 'http://localhost:3000/sp/google-auth';
export const GOOGLE_CAL_SCOPE =
  env.GOOGLE_CAL_SCOPE || 'https://www.googleapis.com/auth/calendar.readonly';
export const GOOGLE_CAL_CLIENT_ID =
  env.GOOGLE_CAL_CLIENT_ID ||
  '257249023874-a6kkqgn48mgu2en20tm0l0dcn2us3rdu.apps.googleusercontent.com';
export const GOOGLE_CAL_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=token&include_granted_scopes=true&client_id=${GOOGLE_CAL_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  GOOGLE_CAL_REDIRECT_URI,
)}&scope=${encodeURIComponent(GOOGLE_CAL_SCOPE)}`;
